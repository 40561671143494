<template>
  <div class="payment-setting">
    <div>
      <div class="container" style="max-width:1200px;margin:auto">
        <md-card-content v-show="!fetching">
          <form v-on:submit.prevent="save(records)">
            <md-table-card class="list" style="width:100%;">
              <md-card-header>
                <h1 class="md-title center">Page Banner</h1>
              </md-card-header>
              <md-table>
                <md-table-header>
                  <md-table-row>
                    <md-table-head>Preview</md-table-head>
                    <md-table-head>Banner(1440 * 280)</md-table-head>
                    <md-table-head>Banner Url</md-table-head>
                    <md-table-head>Rank</md-table-head>
                    <md-table-head>Action</md-table-head>
                  </md-table-row>
                </md-table-header>
                <md-table-body>
                  <md-table-row v-for="(record,idx) in records" :key="idx">
                    <md-table-cell>
                      <img :src="record.image | googleimage" style="width:150px" />
                    </md-table-cell>
                    <md-table-cell>
                      <md-input-container>
                        <image-input v-model="records[idx].image" folder="banner"></image-input>
                      </md-input-container>
                    </md-table-cell>
                    <md-table-cell>
                      <md-input-container>
                        <md-input v-model="records[idx].url" v-validate data-vv-name="url" placeholder="Url"></md-input>
                      </md-input-container>
                    </md-table-cell>
                    <md-table-cell>
                      <md-input-container>
                        <md-input type="number" v-model="records[idx].rank"></md-input>
                      </md-input-container>
                    </md-table-cell>
                    <md-table-cell>
                      <md-button type="button" class="md-icon-button" @click="removeImage(record)">
                        <md-icon>delete</md-icon>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table-body>
              </md-table>
              <md-button class="md-primary" @click="addImage">Add another banner</md-button>
            </md-table-card>
            <submit class="submitButton" :loading="loading" icon="save" text="Submit" :disabled="errors.any()"></submit>
          </form>
        </md-card-content>
      </div>
    </div>
  </div>
</template>
<script>
import Edit from "@/components/Edit";
import ImageInput from "@/components/ImageInput";
import { pageBannerFields } from "@/config";
import Submit from "@/components/Submit";

export default {
    data() {
        return {
            loading: false,
            fetching: true,
            records: [],
            fs: pageBannerFields
        };
    },
    components: {
        Edit,
        Submit,
        ImageInput
    },
    methods: {
        addImage() {
            this.records.push({
                url: null,
                image: "",
                rank: 0
            });
        },
        removeImage(record) {
            this.records = this.records.filter(item => item !== record);
        },
        async getInitData() {
            const data = await this.$store.dispatch("crud/get", {
                api: "settings/banner"
            });
            if (data) {
                this._id = data._id;
                this.records = data.value || [];
            }
            this.fetching = false;
        },
        async save(item) {
            const data = await this.$store.dispatch("crud/put", {
                api: "settings/banner",
                data: {
                    value: item
                }
            });

            this.$store.commit("setMessage", "Save Succeed");
        }
    },
    created() {
        this.getInitData();
    }
};
</script>
